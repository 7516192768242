import React from "react";
import { Card } from "antd";
import { DomainTabs } from "./DomainTabs";

export default function MaturityModel() {
  return (
    <Card>
      <DomainTabs />
    </Card>
  );
}
